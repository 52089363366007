import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import Head from 'components/head';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const Index = () => {
  const {
    defaultBgImage,
    foodBgImage,
    interiorsBgImage,
  } = useStaticQuery(graphql`
    query Background {
      defaultBgImage: cloudinaryMedia(
        public_id: { eq: "newSite/defaultMainBgImage" }
      ) {
        secure_url
      }
      foodBgImage: cloudinaryMedia(
        public_id: { eq: "newSite/foodMainBgImage" }
      ) {
        secure_url
      }
      interiorsBgImage: cloudinaryMedia(
        public_id: { eq: "newSite/interiorsMainBgImage" }
      ) {
        secure_url
      }
    }
  `);

  const [currentBgImage, setBgImage] = useState(
    defaultBgImage.secure_url || null
  );

  return (
    <Layout bgImage={currentBgImage}>
      <Head pageTitle="Commercial Photographer in Bristol, Bath & SW England" />
      <div
        style={{
          fontSize: '5rem',
          margin: '0 auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <div style={{ paddingBottom: '5rem' }}>
          <StyledLink
            onMouseOver={() => setBgImage(interiorsBgImage.secure_url)}
            onMouseOut={() => setBgImage(defaultBgImage.secure_url)}
            to="/interiors"
          >
            Interiors
          </StyledLink>
        </div>
        <div>
          <StyledLink
            onMouseOver={() => setBgImage(foodBgImage.secure_url)}
            onMouseOut={() => setBgImage(defaultBgImage.secure_url)}
            to="/food"
          >
            Food + Product
          </StyledLink>
        </div>
      </div>
    </Layout>
  );
};

const StyledLink = styled(Link)`
  font-size: 6rem;
  text-decoration: none;
  font-family: Mono;
  color: #fff;
  text-transform: uppercase;
`;

export default Index;
